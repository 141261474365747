import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Image, NavDropdown } from 'react-bootstrap';
import cardImg4 from '../iAssets/AR_plain_no_letters.png'

const Navigation = () => {
   
   return (
      <Navbar expand="sm" bg="#0091EA" style={{backgroundColor: '#0071ca', paddingRight:'2%', paddingLeft:'2%' }}>
         <Navbar.Brand href="/"> <Image src={cardImg4} width="60" height="30"/> </Navbar.Brand>
         <Navbar.Brand href="/"><h2 style={{color: 'white'}}>Apple Runway</h2></Navbar.Brand>
         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
         <Navbar.Collapse className="justify-content-end" style={{minheight:'50px', backgroundColor: '#0071ca'}} >         
            <Nav style={{backgroundColor: '#0071ca', margin:'-0 -16px 0 -16px', textAlign:'center'}}>
               <Nav.Link href="https://www.paypal.com/donate?hosted_button_id=6UATTZD4RSFDJ" target="_blank"><h5 style={{color: 'white'}}>Donate</h5></Nav.Link>                                                  
               <Nav.Link href="/auditors"><h5 style={{color: 'white'}}>Audit</h5></Nav.Link>
               <Nav.Link href="/teachers"><h5 style={{color: 'white'}}>Request</h5></Nav.Link>            
            </Nav>
         </Navbar.Collapse>
      </Navbar>
    );
   
}
export default Navigation
