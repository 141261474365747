import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Button, Image, Card, Jumbotron, Figure, Modal, Form, Table } from 'react-bootstrap';
import TeacherForm from './TeacherForm';
import authentication from './authentication'
import cardImg1 from '../iAssets/chalkboard-teacher.svg'

class teachers extends Component {
   constructor(props) {
      super(props);
      this.state = {
         teacherInfo: [props.name, props.id, props.email, props.token],
         numSubmits: 0,
         showModal: false,
         showItemModal: false,
         items: null
      }
      this.updateTeacher = this.updateTeacher.bind(this)
      this.editModalEnabled = this.editModalEnabled.bind(this)
      this.itemsModal = this.itemsModal.bind(this)
      this.itemsModalEnabled = this.itemsModalEnabled.bind(this)
   }

   tokens = this.auth()


   auth() {
      let search = this.props.location.search;
      let params = new URLSearchParams(search);
      let code = params.get("code");
      if (code == null) {
         return false
      }

      let tokens = authentication.authentication(code, "https://applerunway.org/teachers-logged-in")
      
      if (tokens["error"] != null) {
         return false
      }

      let access_token = authentication.decode_tokens(tokens["access_token"])
      let id_token = authentication.decode_tokens(tokens["id_token"])

      let api_gw_token = authentication.get_refresh_token(tokens["refresh_token"])
      if (api_gw_token === "No Token Provided" || access_token === "Invalid Token") {
         return false
      }
      let finalTokens = { group: access_token['cognito:groups'], api_token: api_gw_token, username: access_token['username'], email: id_token["email"] }
      return finalTokens

   }

   get_teacher_requests() {
      let url = new URL('https://2w7mxgcpq1.execute-api.us-east-1.amazonaws.com/apple-runway/pendingTeacherRequests')
      var paramsForURL = { username: this.tokens.username }
      url.search = new URLSearchParams(paramsForURL).toString();
      let request = new XMLHttpRequest();
      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader("Authorization", "Bearer " + this.tokens.api_token)
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send();
      let json = JSON.parse(request.responseText)
      if (json['message'] == "Internal Server Error") {
         return "No Token Provided"
      }
      let myList = json
      return myList
   }

   get_teacher_info() {
      let url = new URL('https://2w7mxgcpq1.execute-api.us-east-1.amazonaws.com/apple-runway/getTeacherInfo')
      var paramsForURL = { username: this.tokens.username }
      url.search = new URLSearchParams(paramsForURL).toString();
      let request = new XMLHttpRequest();
      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader("Authorization", "Bearer " + this.tokens.api_token)
      request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
      request.send();
      let json = JSON.parse(request.responseText)
      if (json['message'] == "Internal Server Error") {
         return "No Token Provided"
      }
      let finalVal = JSON.parse(json["body"])
      return finalVal
   }

   updateTeacher(formSumbit) {
      let count = this.state.numSubmits + 1
      this.setState({
         numSubmits: count
      })
   }

   itemsModal() {
      let show = this.state.showItemModal

      const handleClose = () => this.setState({ showItemModal: false, items: null });
      const handleShow = () => this.setState({ showItemModal: true });

      if (this.state.items != null) {
         return (
            <>

               <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                     <Modal.Title>Your Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Table striped bordered hover responsive>
                        <thead>                           
                           <th>Item</th>
                           <th>Quantity</th>
                           <th>Cost</th>
                        </thead>
                        <tbody>
                        {
                           this.state.items.map((item) =>
                              //render name, quant, dollars
                              <tr>
                                 <td>{item.name}</td>
                                 <td>{item.quant}</td>
                                 <td>{item.dollars}</td>
                              </tr>                                             
                           )
                        }
                        </tbody>
                     </Table>                     
                  </Modal.Body>
               </Modal>
            </>
         );
      }
      else {
         return (
            <>
               <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                     <Modal.Title>Test</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <h1> No Items</h1>
                  </Modal.Body>
               </Modal>
            </>
         );
      }

   }

   editModalEnabled() {
      let show = this.state.show
      this.setState({ show: true })
   }

   itemsModalEnabled(review) {
      let show = this.state.showItemModal
      this.setState({ showItemModal: true, items: review.Items })
   }


   render() {
      if (!this.tokens) {
         return (
            <Jumbotron style={{ backgroundColor: '#ECEFF1', paddingBottom: "0px", minHeight: "100vh" }}>
               <h1>Session Expired Please Re-Login</h1>
               <NavLink to="/teachers" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
            </Jumbotron>
         )
      }
      if (!this.tokens.group.includes('teachers')) {
         return (
            <Jumbotron style={{ backgroundColor: '#ECEFF1', paddingBottom: "0px", minHeight: "100vh" }}>
               <h1>You don't have access to this page :/</h1>
               <NavLink to="/Home" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
            </Jumbotron>
         )
      }
      var allRequests = this.get_teacher_requests()
      var getTeacherInfo = this.get_teacher_info()

      if (allRequests === 'No Token Provided' || getTeacherInfo === 'No Token Provided') {
         return (
            <Jumbotron>
               <h1>Token Wasn't Provided :/</h1>
               <NavLink to="/Home" ><Button variant="primary" style={{ backgroundColor: '#0091EA' }}>Log in or Sign up</Button></NavLink>
            </Jumbotron>
         )

      }


      return (
         <Container fluid style={{ backgroundColor: '#ECEFF1',  minHeight: "100vh", paddingBottom: '50%' }}>
            <this.itemsModal />
            <Row sm-1>
               <Col md="2">
                  <Card style={{}}>
                     <Card.Body>
                        <Row style={{ textAlign: 'center' }}>
                           <Col>
                              <Figure.Image fluid src={cardImg1} height={"150px"} width={"150px"} />
                              <Card.Title>{getTeacherInfo.fname} {getTeacherInfo.lname}</Card.Title>
                              <Card.Subtitle className="mb-2 text-muted">{getTeacherInfo.school}</Card.Subtitle>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <Card.Text>username: {this.tokens.username} </Card.Text>
                              <Card.Text>email:  {this.tokens.email}</Card.Text>                              
                              {/* 
                              <Card.Text>School: {getTeacherInfo.school}</Card.Text>
                              <Card.Text>Pending Reviews: </Card.Text>
                              <Card.Text>Completed Reviews: </Card.Text>
                              */}
                           </Col>
                        </Row>
                        <Row>
                           {/*
                              <Col style={{ textAlign: 'center' }}>
                              <Card.Link href="#"><Button onClick={this.editModalEnabled}>Edit</Button></Card.Link>
                              </Col>
                           */
                           }

                        </Row>
                     </Card.Body>
                  </Card>
               </Col>
               <Col>
                  <Container className="overflow-auto" style={{ width: '100%', maxHeight: '700px', scrollbarWidth: 'thin' }}>
                     <TeacherForm name={this.tokens.username} token={this.tokens.api_token} email={this.tokens.email} onSubmit={this.updateTeacher} />
                  </Container>
               </Col>
               <Col md="3">
                  <Card>
                     <Card.Header><Card.Title style={{ alignSelf: 'center' }}>My Requests</Card.Title></Card.Header>
                     <Card.Body className="overflow-auto" style={{ maxHeight: '700px', scrollbarWidth: 'thin' }}>
                        <Row md={1}>
                           {
                              allRequests.map((review) =>

                                 <Card>
                                    <Card.Header><Card.Title style={{ textAlign: 'center' }}>{review.teacherName}</Card.Title></Card.Header>
                                    <Card.Body>

                                       <Row>
                                          <Col>
                                             <Card.Subtitle className="mb-2 text-muted"><h6>Estimated Cost: {review.cost}</h6></Card.Subtitle>                                             
                                          </Col>
                                          <Col>
                                             <Card.Subtitle className="mb-2 text-muted"><h6>Approval Status: {review.approval}</h6></Card.Subtitle>
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <Card.Subtitle className="mb-2 text-muted"><h6>Date Submitted: {(review.Date).slice(0, 10)}</h6></Card.Subtitle>
                                          </Col>
                                       </Row>
                                       <Card.Text style={{ textAlign: 'center' }}>
                                          <Button onClick={() => this.itemsModalEnabled(review)}>View Items</Button>
                                       </Card.Text>
                                    </Card.Body>
                                 </Card>
                              )
                           }
                        </Row>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </Container>
      )

   }
}

export default teachers

