import React, { Component } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

class reviewForm extends Component {
   state = {
      reviews: [
         { teacherName: "Mr. Egg", school: "Yolk City Elementry", dateSubmitted: "5/10/20", formID: '123', items: ["pencil", "pen", "paper"], status: "New" }      
      ]
   }
   render() {

      return (
         <Container>
            <Row style={{alignSelf:'center'}}>
               {
                  this.state.reviews.map((review) =>
                     <Col>
                     <Card>
                        <Card.Body>
                           <Card.Title>{review.teacherName}</Card.Title>
                           <Card.Subtitle className="mb-2 text-muted">{review.school}</Card.Subtitle>
                           <Card.Text>
                              <Container>
                              {review.items.map((item) =>
                                 <li>{item}</li>
                              )}

                              </Container>
                           </Card.Text>
                           <Card.Link href="#"><Button>Approve</Button></Card.Link>                           
                           <Card.Link href="#"><Button>Decline</Button></Card.Link>                           
                        </Card.Body>
                     </Card>
                     </Col>                                          
                  )
               }
            </Row>

         </Container>
      );
   }
}

export default reviewForm