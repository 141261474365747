import { Input } from 'aws-amplify-react/lib-esm/AmplifyTheme';
import React, { Component } from 'react';
import { Form, Button, Container, Row, Card, Col } from 'react-bootstrap';

class teacherForm extends Component {

   constructor(props) {
      super(props);
      this.state = {
         teacherInfo: [props.name, props.email, props.token],
         items: [{ name: "", quant: "", dollars: "" }],
         itemsValidation: [{ name: "", quant: "", dollars: "" }],
         reason: "",
         reasonValidate: false,
         submitDisabled: true
      }
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleReason = this.handleReason.bind(this)
   }

   addItem = (e) => {
      this.setState((prevState) => ({
         items: [...prevState.items, { itemName: "", quant: "", dollars: "" }],
         itemsValidation: [...prevState.itemsValidation, { name: "", quant: "", dollars: "" }]
      }))
   }

   removeItem = (e) => {
      const items = this.state.items
      const itemsValidation = this.state.itemsValidation
      if (this.state.items.length != 1) {
         items.splice(items.size, 1)
         itemsValidation.splice(itemsValidation.size, 1)
         this.setState({ items, itemsValidation })
         /*this.setState((prevState) => ({
            items: [...prevState.items, { itemName: "", quant: "", dollars: "" }],
            itemsValidation: [...prevState.itemsValidation, { name: false, quant: false, dollars: false }]
         }))*/
      }

   }

   handleChange = (e) => {
      let items = [...this.state.items]
      let itemsValidation = [...this.state.itemsValidation]
      items[e.target.id][e.target.name] = e.target.value
      if (e.target.name === "name") {
         let decision = false
         let letters = /^[A-Za-z\s]+$/


         if (!e.target.value.match(letters)) {
            decision = true
         }
         if (e.target.value.match(letters)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }
      if (e.target.name === "quant") {
         let decision = false
         let goodnumbers = /^[0-9]+$/
         if ((!e.target.value.match(goodnumbers)) || (e.target.value === "" )) {
            decision = true
         }
         if (e.target.value.match(goodnumbers)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }
      if (e.target.name === "dollars") {
         let decision = false

         let goodnumbers = /^[0-9]+$/
         if (!e.target.value.match(goodnumbers)) {
            decision = true
         }
         if (e.target.value.match(goodnumbers)) {
            decision = false
         }
         itemsValidation[e.target.id][e.target.name] = decision
      }

      this.setState({ items, itemsValidation })
      console.log(itemsValidation)

      let anyValue = false
      itemsValidation.forEach(element => {
         if (element.dollars === true || element.name === true || element.quant === true) {
            anyValue = true
         }
      });

      if (anyValue || (this.state.reasonValidate === true)) {
         this.setState({ submitDisabled: true })
      }
      if (!anyValue && (this.state.reasonValidate === false)) {
         this.setState({ submitDisabled: false })
      }
   }

   handleReason = (e) => {
      let reason = e.target.value
      let decision = false
      //let letters = /^[a-zA-Z0-9,.!? ]+$/
      let letters = /^[a-zA-Z0-9$',/.\s\\-]+$/

      if (!e.target.value.match(letters)) {
         decision = true
      }
      if (e.target.value.match(letters)) {
         decision = false
      }
      let reasonValidate = decision

      this.setState({ reason, reasonValidate })

      let anyValue = false
      this.state.itemsValidation.forEach(element => {
         if (element.dollars === true || element.name === true || element.quant === true) {
            anyValue = true
         }
      });

      if (anyValue || (decision === true)) {
         this.setState({ submitDisabled: true })
      }
      if (!anyValue && (decision === false)) {
         this.setState({ submitDisabled: false })
      }

   }

   handleSubmit = (e) => {
      e.preventDefault()
      if (!this.state.submitDisabled) {
         let totalCost = 0
         //new Date().toISOString().slice(0, 10)
         let submitDate = new Date().toISOString()

         this.state.items.forEach(element => {
            totalCost += parseInt(element.dollars)
         });

         let consoleItems = this.state.items
         let consoleReason = this.state.reason

         //submit date
         var jsonBlob = { teacherName: this.state.teacherInfo[0], cost: totalCost, Items: consoleItems, teacherEmail: this.state.teacherInfo[1], reason: consoleReason, Date: submitDate }

         var url = new URL('https://2w7mxgcpq1.execute-api.us-east-1.amazonaws.com/apple-runway/createTeacherRequests')
         var request = new XMLHttpRequest();
         request.open('POST', url, false);  // `false` makes the request synchronous
         request.setRequestHeader("Authorization", "Bearer " + this.state.teacherInfo[2])
         request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
         request.send(JSON.stringify(jsonBlob));


         var json = JSON.parse(request.responseText)

         if (json['message'] == "Internal Server Error") {
            return "Unable to Run Lambda Accurately"
         }

         this.props.onSubmit(json)
         this.setState({
            items: [{ name: "", quant: "", dollars: "" }],
            reason: ""
         })
      }
      

   }

   render() {
      let { items } = this.state
      return (
         <div>
            <Container>
               <Form>
                  <Card>
                     <Card.Header><Card.Title style={{ textAlign: 'center' }}>Submit a New Request</Card.Title></Card.Header>
                     <Card.Body >
                        <Row >
                           <Col >

                              <Form.Text className="text-muted">
                                 <Row>
                                    <Col>{this.state.teacherInfo[0]} </Col>
                                    <Col>{this.state.teacherInfo[1]}</Col>
                                 </Row>
                              </Form.Text>
                              {
                                 items.map((val, idx) => {
                                    let nameId = `Item-${idx}`, quantId = `quant-${idx}`, dollarsId = `dollars-${idx}`
                                    return (
                                       <Row>
                                          <Col>
                                             <div key={idx}>

                                                <Form.Group controlId={idx} >
                                                   <Form.Control id={idx} value={this.state.items[idx].name} name="name" isInvalid={this.state.itemsValidation[idx].name} onChange={this.handleChange}  size="sm" type="text" placeholder="Item name" />
                                                   <Form.Control.Feedback type="invalid">Enter only letters</Form.Control.Feedback>
                                                </Form.Group>
                                             </div>
                                          </Col>
                                          <Col>
                                             <div key={idx}>
                                                <Form.Group controlId={idx}>

                                                   <Form.Control id={idx} name="quant" value={this.state.items[idx].quant} isInvalid={this.state.itemsValidation[idx].quant} onChange={this.handleChange} size="sm" type="text" placeholder="Quantity" />
                                                   <Form.Control.Feedback type="invalid">Enter only whole numbers</Form.Control.Feedback>
                                                </Form.Group>
                                             </div>
                                          </Col>
                                          <Col>
                                             <div key={idx}>
                                                <Form.Group controlId={idx}>

                                                   <Form.Control id={idx} name="dollars" value={this.state.items[idx].dollars} isInvalid={this.state.itemsValidation[idx].dollars} onChange={this.handleChange} size="sm" type="text" placeholder="$ Estimated Cost" />
                                                   <Form.Control.Feedback type="invalid">Enter only whole numbers</Form.Control.Feedback>
                                                </Form.Group>
                                             </div>
                                          </Col>
                                          <Col>
                                             <div key={idx}>
                                                <Form.Group controlId={idx}>
                                                   <Button variant="danger" onClick={this.removeItem} >-</Button>
                                                </Form.Group>
                                             </div>
                                          </Col>

                                       </Row>
                                    )
                                 })
                              }
                              <Form.Group>
                                 <Button onClick={this.addItem}>Add an Item</Button>
                                 <Form.Control name="reason" value={this.state.reason} isInvalid={this.state.reasonValidate} onChange={this.handleReason} size="sm" type="text" placeholder="Reason" />
                                 <Form.Control.Feedback type="invalid">enter valid text</Form.Control.Feedback>
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row>
                           {/*
                           <Col style={{ textAlign: 'center' }}>
                              <Button onClick={this.addItem}>Add an Item</Button>
                           </Col>
                           <Col style={{ textAlign: 'center' }}>
                              <Button variant="danger" onClick={this.removeItem} >Remove an Item</Button>
                           </Col>                           
                           */}
                           <Col style={{ textAlign: 'center' }}>
                              <Button type='submit' onClick={this.handleSubmit}>Submit</Button>
                              {/*<Button type='submit' disabled={this.state.submitDisabled} onClick={this.handleSubmit}>Submit</Button>*/}
                           </Col>
                        </Row>
                     </Card.Body>
                  </Card>
               </Form>
            </Container>
         </div>
      )

   }
}

export default teacherForm

