import React from 'react';
import { NavLink } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Navbar, Image } from 'react-bootstrap';
import cardImg4 from '../iAssets/AR_plain_no_letters_white.png'
import linkdinImg from '../iAssets/LI-In-Bug-white.png'
import instaImg from '../iAssets/insta.svg'
const Footer = () => {
    return (
        <footer style={{ display: 'block', textAlign: 'center', position:'relative', bottom:'0'}}>

            <Container fluid text-center style={{
                bottom: "0",
                width: "100%",
                padding: "10px 15% 10px 15%",
                backgroundColor: '#004399',

            }}
            >
                <Row style={{textAlign:'center'}}>
                    
                    <Col><h1 style={{ color: 'white', paddingBottom: '5px' }}>Apple Runway</h1></Col>
                    

                </Row>
                <Row style={{textAlign:'center'}}>
                    
                    <Col>
                        <a href="https://www.linkedin.com/company/applerunway/" target="_blank"><Image src={linkdinImg} style={{ margin: '0 10px 0 10px', display: "inline", borderRadius: "10%", width: '30px', height: '30px', alignSelf: 'center' }} /></a>
                        <a href="https://applerunway.org/"><Image src={cardImg4} style={{ margin: '0 10px 0 10px', display: "inline", borderRadius: "10%", width: '50px', height: '30px', alignSelf: 'center' }} /></a>
                        <a href="https://www.instagram.com/applerunway/"  target="_blank"><Image src={instaImg} style={{ margin: '0 10px 0 10px', display: "inline", borderRadius: "10%", width: '30px', height: '30px', alignSelf: 'center' }} /></a> 
                    </Col>
                    
                </Row>
                <Row style={{textAlign:'center'}}>
                    
                    <Col><h4 style={{ color: '#cfd8dc', marginBottom:'-10%' }}><small>Apple Runway is a 501(c)(3) Nonprofit Organization</small></h4></Col>
                    

                </Row>
                {/*
            <Container style={{textAlign: 'center', color: 'white'}}>
                    
                    <h1 style={{ paddingBottom: '5px' }}>Apple Runway</h1>
                
                    <a href="https://www.linkedin.com/company/community-desk/"><Image src={linkdinImg} style={{ margin:'0 10px 0 10px', display:"inline", borderRadius: "10%", width: '50px', height: '50px', alignSelf: 'center' }} /></a>
                    <a href="https://applerunway.org/"><Image src={cardImg4} style={{ margin:'0 10px 0 10px', display:"inline", borderRadius: "10%", width: '70px', height: '50px', alignSelf: 'center' }} /></a>
                    <a href="https://www.instagram.com/communitydesk/"><Image src={instaImg} style={{ margin:'0 10px 0 10px', display:"inline", borderRadius: "10%", width: '50px', height: '50px', alignSelf: 'center' }} /></a>       
                
                <div style={{textAlign: 'center', marginTop:'10px'}}>
                    <h4 style={{color:'#cfd8dc'}}><small>Apple Runway is a 501(c)(3) nonprofit organization</small></h4>
                </div>                                
                                                      
                
            </Container>
            */

                }

                {/*
            <Row>
                <Col></Col>
                <Col>
                    <p>image</p>
                </Col>
                <Col>
                    <h5>Recommended Supplies</h5>
                    <ul>
                        <li>Pencils</li>
                        <li>Pens</li>
                        <li>Binders</li>
                        <li>Books</li>
                    </ul>
                </Col>
                <Col>
                    <h5>Web articles</h5>
                    <ul>
                        <li>Wikipedia</li>
                        <li>Reddit</li>
                        <li>Best Practices</li>
                        <li>Subscriptions</li>
                    </ul>
                </Col>
                <Col></Col>
            </Row>
            */}
            </Container >            
        </footer>

    );
}
export default Footer